import React, { useState } from 'react'
import ReviewCard from '../simple/ReviewCard'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLangContext } from '../../i18n/ProvideLang';
import ReviewModal from '../simple/ReviewModal';

const Reviews = ({item}) => {
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        speed: 1200,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }]
    };

  return (
	<div className="section section-x tc-grey back-color reviews" id='#reviews'>
		<div className="container">
            <div className="row justify-content-center text-center">
				<div className="col-12">
					<div className="section-head section-md">
						{item.subtitle && <h5 className="heading-xs dash dash-both">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
					</div>
				</div>
			</div>
			<Slider {...settings}>
				{item.elements?.map((card, index) =>
				    <ReviewCard key={index} card={card}></ReviewCard>
				)}
			</Slider>
            <div className="row justify-content-center mt-100">
                <div className="col-auto">
                    <div className="btn" onClick={() => setShow(true)}>{localizationsItems?.leave_review}</div>
                </div>
            </div>
		</div>
        {show && <ReviewModal show={show} setShow={setShow}/>}
	</div>
  )
}

export default Reviews