import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap';

const CollapseItem = ({card}) => {
    const [open, setOpen] = useState(false);
  return (
    <div className='faq'>
        <div className="faq-item bg-light" onClick={() => setOpen(!open)} aria-expanded={open}>
			<h4>{card.title}</h4>
            <span>{open ? '-' : '+'}</span>
		</div>
        <Collapse in={open}>
            <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
        </Collapse>
    </div>
  )
}

export default CollapseItem