import React from 'react'

const Advantages = ({item}) => {
  return (
	<div className="section section-x tc-grey-alt">
		<div className="container">
			<div className="row justify-content-between gutter-vr-30px">
				<div className="col-lg-4 col-xl-3 col-md-8">
					<div className="section-head section-head-col">
                        {item.subtitle && <h5 className="heading-xs dash">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
						<p className="lead" dangerouslySetInnerHTML={{ __html: item.description }}></p>
					</div>
				</div>
				<div className="col-lg-8">
					<div className="row gutter-vr-30px gutter-vr-30px-res">
                        {item.elements?.map((elem, index) =>
                            <div className="col-sm-6" key={index}>
                                <div className="feature">
                                    <div className="feature-icon">
                                        <img src={elem.image} alt="" />
                                    </div>
                                    <div className="feature-content">
                                        <h3>{elem.title}</h3>
                                        <p>{elem.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        )}
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Advantages