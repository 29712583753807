import React from 'react'

const AboutItem = ({card}) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="feature">
            <div className="icon-img">
                <img src={card.image} alt="" />
            </div>
            <div className="feature-content feature-content-s4">
                <h4>{card.title}</h4>
                <p>{card.subtitle}</p>
            </div>
        </div>
    </div>
  )
}

export default AboutItem