import React from 'react'

const TextComponent = ({item}) => {
  return (
    <div className='text-component'>
		<div className="container">
            <div className="row justify-content-center text-center">
				<div className="col-12">
					<div className="section-head section-md">
						{item.subtitle && <h5 className="heading-xs dash dash-both">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
					</div>
				</div>
			</div>
            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
        </div>
    </div>
  )
}

export default TextComponent