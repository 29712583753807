import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import ModalForm from '../complex/ModalForm';
import { useNavigate } from 'react-router-dom';

const ServiceCard = ({card}) => {
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);

    const handleShow = () => {
		setShow(true);
	}

  return (
    <div className="col-lg-4 col-md-6">
        {card.link ?      
            <div className="pricing-boxed">
                <img src={card.image} alt="" />
                <a href={card.link} target='_blank' className="pricing-price">
                    <h3><span className="price-for">{card.title}</span></h3>
                </a>
                <div className="pricing-feature">
                    <h4>{card.subtitle}</h4>
                    <p className="description" dangerouslySetInnerHTML={{ __html: card.description }}></p>
                </div>
                {localizationsItems?.service_button && <div className="pricing-cta">
                    <div className="btn" onClick={handleShow}>{localizationsItems?.service_button}</div>
                </div>}
            </div>
        :
            <div className="pricing-boxed">
                <img src={card.image} alt="" />
                <div className="pricing-price">
                    <h3><span className="price-for">{card.title}</span></h3>
                </div>
                <div className="pricing-feature">
                    <h4>{card.subtitle}</h4>
                    <p className="description" dangerouslySetInnerHTML={{ __html: card.description }}></p>
                </div>
                {localizationsItems?.service_button && <div className="pricing-cta">
                    <div className="btn" onClick={handleShow}>{localizationsItems?.service_button}</div>
                </div>}
            </div>
        }
        {show && <ModalForm show={show} setShow={setShow}/>}
    </div>
  )
}

export default ServiceCard