import React from 'react'
import Slider from "react-slick";
import Fancybox from './FancyBox';
import img from "../../images/placeholder.png"

const VideoComponent = ({item}) => {
    const options = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        speed: 1200,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }]
    }

  return (
	<div className="section section-x section-project pb-0 tc-grey-alt" >
        <div className='text-center'>
            <div className="container">
                    <div className="portfolio-wrapper">
                        <div className="section-head">
                            <h2>{item.title}</h2>
                            {item.subtitle && <h5 className="heading-xs">{item.subtitle}</h5>}
                        </div>
                    </div>
            </div>
        </div>
		<div className="container">
            <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                <Slider className="videos-slider" {...options}>
                    {item?.elements?.map((card, index) =>
                        <a href={card.url ? card.url : card.image} data-fancybox="gallery" key={index}>
                            <div className='video-item'>
                                <img src={card.image ? card.image : img} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                />
                                {card.url && <span className='video-span'>
                                    <i className='fa fa-play'></i>
                                </span>}
                            </div>
                        </a>
                    )}
                </Slider>
            </Fancybox>
		</div>
	</div>
  )
}

export default VideoComponent