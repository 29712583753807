import React, { useState } from 'react'
import ServiceCard2 from '../simple/ServiceCard2'
import ServiceModal from './ServiceModal';

const ServicesComponent = ({item}) => {
    const [show, setShow] = useState(false);
    const [card, setCard] = useState();

    const handleShow = (elem) => {
        setCard(elem);
		setShow(true);
	}

  return (
	<div className="section">
		<div className="container">
            <div className="row justify-content-center text-center">
				<div className="col-12">
					<div className="section-head section-md">
						{item.subtitle && <h5 className="heading-xs dash dash-both">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-center gutter-vr-30px">
				{item.elements?.map((card, index) =>
				    <ServiceCard2 card={card} key={index} onClick={() => handleShow(card)}/>
				)}
			</div>
		</div>
        {show && <ServiceModal show={show} setShow={setShow} card={card} image={item.steps_image}/>}
	</div>
  )
}

export default ServicesComponent