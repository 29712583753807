import React from 'react'

const PricePackages = ({item}) => {
  return (
    <div className="section section-x section-counter advertising">
        <div className="container">
            <div className='packages'>
                <div className='hr-line'>
                    <h3>{item.title}</h3>
                    <div className='line' />
                </div>
                <div className="row">
                    {item.elements?.map((card, index) =>
                        <div className='col-md-6 col-lg-4 package-item' key={index}>
                            <div className='img-flex'>
                                <img src={card.image} alt="" />
                                <p>{card.subtitle}</p>
                            </div>
                            <div className='package-list' dangerouslySetInnerHTML={{__html: card.description}}></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className="bg-image bg-fixed">
            <img src={item.review_image} alt=""/>
        </div>
    </div>
  )
}

export default PricePackages