import MainPage from "../pages/main/MainPage";
import SamplePage from "../pages/sample/SamplePage";
import {  MAIN_PAGE_ROUTE, SAMPLE2_PAGE_ROUTE, SAMPLE_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: SAMPLE_PAGE_ROUTE,
        Component: SamplePage
    },
    {
        path: SAMPLE2_PAGE_ROUTE,
        Component: SamplePage
    }
]