import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import img1 from "../../images/modal.png";

const WorkerModal = ({show, setShow, card, image}) => {

    const handleClose = () => {
		setShow(false);
	}

  return (
    <Modal show={show} onHide={handleClose} centered className='modal-main-worker'>
        <Modal.Header className="p-0">
            <button type="button" className="btn-close" onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
            </button>
        </Modal.Header>
        <Modal.Body>
            <div className='worker-modal'>
                <div className='worker-content'>
                    <img src={card.image} alt="" />
                    <div>
                        <h3>{card.name}</h3>
                        {card.position && <div className='position'>{card.position}</div>}
                        <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <div className='modal-img worker-modal-img'>
            <img src={image ? image : img1} alt="" />
        </div>
    </Modal>
  )
}

export default WorkerModal