import React from 'react'

const Partners = ({item}) => {
  return (
	<div className="section section-x">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-lg-5 text-center">
					<div className="section-head section-md">
						{item.subtitle &&<h5 className="heading-xs dash dash-both">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-center gutter-vr-40px">
				{item?.elements?.map((card, index) =>
					<div className="col-sm-2 col-4 col-center" key={index}>
						<div className="logo-item">
							<img src={card.image} alt=""/>
						</div>
					</div>
				)}
			</div>
		</div>
	</div>
  )
}

export default Partners