import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const WhoWeAre = ({item}) => {
    const {localizationsItems} = useLangContext();
	const [image, setImage] = useState();

	useEffect(() => {
		if(item){
			setImage(item.elements?.find(it => it.type === "images").elements[0])
		}
	}, [item])

    const [visible, setVisible] = useState();

    const handleVisible = () => {
        setVisible(!visible)
    }

  return (
	<div className="section section-x tc-grey">
		<div className="container">
			<div className="row justify-content-between align-items-center gutter-vr-30px">
				<div className="col-xl-7 col-lg-6">
					<div className="image-block">
						<img src={image} alt=""/>
					</div>
				</div>
				<div className="col-xl-5 col-lg-6">
					<div className="text-block text-box-ml mtm-15">
						<h2>{item.title}</h2>
						{item.subtitle && <p className="lead">{item.subtitle}</p>}
                        <div className='hidden-about'>
                            <div className={visible ? 'description-about active' : 'description-about'} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                            {item.description?.length > 1500 && <span className='text-color' onClick={handleVisible}>
                                {visible ? localizationsItems?.hide_on : localizationsItems?.view_all}</span>}
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default WhoWeAre