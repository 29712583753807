import React, { useEffect, useState } from 'react'
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import ModalForm from './ModalForm';

const MainBanner = ({item}) => {
	const [logo, setLogo] = useState();
	const [image, setImage] = useState();
	const [imageBack, setImageBack] = useState();
    const [values, setValues] = useState();
	const [show, setShow] = useState(false);
	const {localizationsItems} = useLangContext();

	const {
        items,
    } = useFetchItems(AppServices.settings)

	useEffect(() => {
        setLogo(items?.find(it => it.type === "logo").value)
	}, [items])

    useEffect(() => {
        if(item){
            setImage(item.elements.find(it => it.type === "banner_image")?.image)
            setImageBack(item.elements.find(it => it.type === "banner_image_back")?.image)
            setValues(item.elements.find(it => it.type === "banner_items")?.elements)
        }
    }, [item])


	const handleShow = () => {
		setShow(true);
	}

  return (
		<div className="banner">
				<div className="banner-block banner-block-creative">
					<div className="container-custom position-relative">
						<div className="row ">
							<div className="col-lg-12">
								<div className='logo-img'>
									<NavLink to={'/'}>
										<img src={logo} alt="" />
									</NavLink>
								</div>
								{values?.find(it => it !== '') && <div className='banner-items'>
									{values.map((value, index) =>
										<div className='banner-item' key={index}>{value}</div>
									)}
								</div>}
								<div className="banner-content-creative">
									<h1 className="banner-title t-u" dangerouslySetInnerHTML={{ __html: item.title }}></h1>
									<p className="lead lead-lg" dangerouslySetInnerHTML={{ __html: item.description }}></p>
									<div className='offer' dangerouslySetInnerHTML={{ __html: item.subtitle }}></div>
									<div className="banner-btn-creative">
										<div className="btn-creative btn-banner" onClick={handleShow}>{localizationsItems?.banner_button}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{imageBack && <div className="bg-image">
						<img src={imageBack} alt="banner"/>
					</div>}
					{image && <div className='banner-after'>
						<img src={image} alt="" />
					</div>}
				</div>
				{show && <ModalForm show={show} setShow={setShow}/>}
		</div>
  )
}

export default MainBanner