import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const ServiceCard2 = ({card, ...props}) => {
    const {localizationsItems} = useLangContext();

  return (
    <div className="col-lg-4 col-md-6" {...props}>
        <div className="service-card">
            <img src={card.image} alt="" />
            <div className="service-title">
                <h3>{card.title}</h3>
            </div>
            <div className="service-text">
                <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
            </div>
            <div className="service-btn">
                <div>{localizationsItems?.detail}</div>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard2