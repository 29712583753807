import React from 'react'

const ServicePrice = ({item}) => {
  return (
    <div className="section section-x section-counter advertising">
        <div className="container2">
            <div className='packages'>
                {item?.elements?.map((card, index) =>
                    <div key={index} className='mb-20'>
                        <div className='hr-line'>
                            <h3>{card.title}</h3>
                            <div className='line' />
                        </div>
                        <div className="row">
                            {card.elements?.map((it, index) =>
                                <div className='col-md-6 col-lg-3 package-item' key={index}>
                                    <div className='img-flex'>
                                        <img src={it.image} alt="" />
                                        <p>{it.title}</p>
                                    </div>
                                    <div className='package-list' dangerouslySetInnerHTML={{__html: it.description}}></div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className="bg-image bg-fixed">
            <img src={item.review_image} alt=""/>
        </div>
    </div>
  )
}

export default ServicePrice