import React, { useEffect, useState } from 'react'

const AdvertisingBanner = ({item}) => {
    const [banner, setBanner] = useState();

    useEffect(() => {
        if(item){
            setBanner(item.elements?.find(it => it.type === "banner_image")?.image)
        }
    }, [item])
    
  return (
	<div className="section section-x section-counter advertising">
		<div className="container">
            <div className='subtitle' dangerouslySetInnerHTML={{__html: item.subtitle}}></div>
            <h2 className='banner-title t-u' dangerouslySetInnerHTML={{__html: item.title}}></h2>
            <div className='description' dangerouslySetInnerHTML={{__html: item.description}}></div>
        </div>
        <div className="bg-image bg-fixed">
            <img src={banner} alt=""/>
        </div>
    </div>
  )
}

export default AdvertisingBanner