import React, { useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const ReviewCard = ({card}) => {
    const {localizationsItems} = useLangContext();
    const [visible, setVisible] = useState();

    const handleVisible = () => {
        setVisible(!visible)
    }

  return (
    <div className='reviews-card'>
        <div className='hidden-text'>
            <div className={visible ? 'description active' : 'description'} dangerouslySetInnerHTML={{__html: card.description}}></div>
            {card.description?.length > 330 && <span className='text-color' onClick={handleVisible}>
                {visible ? localizationsItems?.hide_on : localizationsItems?.view_all}</span>}
        </div>
        <h3>{card.title}</h3>
        <div className='reviews-subtitle'>{card.subtitle}</div>
    </div>
  )
}

export default ReviewCard