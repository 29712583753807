import React, { useEffect, useState } from 'react'
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import ModalForm from './ModalForm';
import HeaderTop from '../simple/HeaderTop';
import MenuItem from '../simple/MenuItem';

const Header = ({item}) => {
	const [sticky, setStiky] = useState();
    const [logo, setLogo] = useState();
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false);
    const [logoEnabled, setLogoEnabled] = useState(false);
    const [headerButton, setHeaderButton] = useState(false);
    const [headerTopEnabled, setHeaderTopEnabled] = useState(false);
    const [socials, setSocials] = useState();

    const [isMob, setIsMob] = useState(false)

	const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    useEffect(() =>{
        if(item){
            setLogo(item?.find(it => it.type === "logo")?.value)
            setLogoEnabled(item?.find(it => it.type === "header-logo-enabled")?.value)
            setHeaderButton(item?.find(it => it.type === "header-button-enabled")?.value)
            setSocials(item.find(it => it.type === "socials")?.value);
            setHeaderTopEnabled(item.find(it => it.type === "header-top-enabled")?.value);
        }
    }, [item])

	const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 20){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const handleShow = () => {
		setShow(true);
	}

  return (
	<header className={sticky ? "is-sticky is-shrink is-boxed header-s1 has-fixed" : "is-sticky is-shrink is-boxed header-s1"}>
		<div className="header-box">
            {headerTopEnabled && <HeaderTop item={item} socials={socials}/>}
			<div className="header-main">
				<div className="header-wrap">
					<div className="header-logo logo">
                    {logoEnabled &&
						<NavLink to="/" className="logo-link">
							<img className="logo-white" src={logo} alt="logo"/>
						</NavLink>}
					</div>
					<div className="header-nav-toggle">
						<div className="navbar-toggle" data-menu-toggle="header-menu" onClick={() => setIsMob(true)}>
							<div className="toggle-line">
								<span></span>
							</div>
						</div>
					</div>
					<div className={isMob ? "header-navbar menu-mobile menu-shown" : "header-navbar"}>
						<nav className="header-menu" id="header-menu">
							<ul className="menu">
								{items?.map((it, index) =>
                                    it.items ?
                                        <MenuItem key={index} it={it} setIsMob={setIsMob}/>
                                        :
                                        <li className={"menu-item"} key={index}>
                                            {it.value?.startsWith('#') ?
                                                <a href={it.value} onClick={() => setIsMob(false)}>{it.title}</a>
                                            :
                                                <NavLink onClick={() => setIsMob(false)} className="menu-link nav-link menu-toggle" target={it.type} to={it.value}>{it.title}</NavLink>
                                            }
                                        </li>
								)}
							</ul>
							{headerButton && <ul className="menu-btns">
								<li><button className="btn btn-sm" onClick={handleShow}>{localizationsItems?.button_text}</button></li>
							</ul>}
						</nav>
                        {socials?.find(el => el.value) &&
                            <div className='header-bottom'>
                                {socials.map((sc, index) =>
                                    sc.value &&
                                    sc.type === "tiktok" ?
                                        <a key={index} href={sc.value} target='_blank'><svg width="15" height="28" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="white"/>
                                        </svg></a>
                                        :
                                        <a href={sc.value} target='_blank' key={index} className={
                                                        sc.type === "facebook" ? "fab fa-facebook-f" :
                                                        sc.type === "instagram" ? "fab fa-instagram" : 
                                                        sc.type === "dribbble" ? "fab fa-dribbble" : 
                                                        sc.type === "twitter" ? "fab fa-twitter" :  
                                                        sc.type === "telegram" ? "fab fa-telegram" :
                                                        sc.type === "vk" ? "fab fa-vk" :
                                                        sc.type === "whatsapp" ? "fab fa-whatsapp" :
                                                        "fab fa-facebook-f"
                                                    }></a>
                                )}
                            </div>
                        }
                        <div className="header-navbar-overlay" onClick={() => setIsMob(false)}></div> 
					</div>
				</div>
			</div>
		</div>
        {show && <ModalForm show={show} setShow={setShow}/>}
	</header>
  )
}

export default Header