import React, { useEffect, useRef, useState } from 'react'
import PortfolioItem from '../simple/PortfolioItem'
import Fancybox from './FancyBox';

const Portfolio = ({item}) => {
	const [activeItem, setActiveItem] = useState();

	const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    
    const handleShow = (index) => {
        setShow(true);
        setActiveItem(index);
    };

  return (
	<div className="section section-x section-project pb-0 tc-grey-alt" id="project">
        <div className='portfolio-head'>
            <div className="container">
                    <div className="portfolio-wrapper">
                        <div className="section-head">
                            <h2>{item.title}</h2>
                            {item.subtitle && <h5 className="heading-xs">{item.subtitle}</h5>}
                        </div>
                    </div>
            </div>
        </div>

		<Fancybox className="project-area bg-secondary"
			options={{
				Carousel: {
				infinite: false,
				},
			}}
		>
			<div className="row project project-v5 no-gutters" id="project1">
					{item.elements?.map((card, index) =>
						<a data-fancybox="gallery" href={card} className="col-sm-6 col-md-4 col-xl-3 filtr-item" key={index}>
							<div className="project-item">
								<div className="project-image">
									<img src={card} alt="" />
								</div>
							</div>
						</a>
					)}
			</div>
		</Fancybox>

	</div>
  )
}

export default Portfolio