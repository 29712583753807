import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import Widgets from '../simple/Widgets';
import Widgets2 from '../simple/Widgets2';
import ToTop from '../simple/ToTop';
import { useLangContext } from '../../i18n/ProvideLang';

const Footer = ({item, footerEnabled}) => {
    const [logo, setLogo] = useState();
    const [text, setText] = useState();
    const [widgets, setWidgets] = useState();
    const [widgetsOpen, setWidgetsOpen] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [socials, setSocials] = useState();
    const {localizationsItems} = useLangContext();

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "white-logo")?.value)
            setText(item.find(it => it.type === "footer_text"))
            setWidgets(item.find(it => it.type === "widgets")?.value)
            setWidgetsOpen(item.find(it => it.type === "widgets-open")?.value)
            setEmail(item.find(it => it.type === "email")?.value);
            setAddress(item.find(it => it.type === "address")?.value);
            setPhone(item.find(it => it.type === "phone")?.value);
            setSocials(item.find(it => it.type === "socials")?.value);
        }
    }, [item])

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

  return (
    <>
    {footerEnabled && <footer className="section footer footer-s1">
        <div className="container">
            <div className="row gutter-vr-30px">
                <div className="col-lg-12 col-sm-12">
                    <div className="wgs">
                        <div className="wgs-content">
                            <div className="wgs-logo">
                                <NavLink to="/">
                                    <img src={logo} alt="logo"/>
                                </NavLink>
                            </div>
                            {text?.value.map((em, index) => 
                                <p key={index}>{em}</p>
                            )}
                        </div>
                    </div>
                </div>
                {items?.map((elem, index) =>
                <div className="col-lg-3 col-sm-6" key={index}>
                    <div className="wgs">
                        <div className="wgs-content">
                        <h3 className="wgs-title">{elem.title}</h3>
                        <ul className="wgs-menu">
                            {elem.items?.map((el, index) =>
                                <li key={index}>
                                    {el.value?.startsWith('#') ?
                                        <a href={el.value}>{el.title}</a>
                                        :
                                        <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                                    }
                                    </li>
                            )}
                        </ul>
                        </div>
                    </div>
                </div> 
                )}
                {(email || phone || address) && <div className="col-lg-3 col-sm-6">
                    <div className="wgs">
                        <div className="wgs-content">
                            <h3 className="wgs-title">{localizationsItems?.conctacts}</h3>
                            <ul className="wgs-menu">
                                {email?.map((card, index) =>
                                    <li key={index}>
                                        <a href={`mailto:card`}>{card}</a>
                                    </li>
                                )}
                                {phone?.map((card, index) =>
                                    <li key={index}>
                                        <a href={`tel:card`}>{card}</a>  
                                    </li>
                                )}
                                {address?.map((card, index) =>
                                    <li key={index}>
                                        {card}     
                                    </li>
                                )}
                                <li className='socials'>
                                    {socials?.find(el => el.value) &&
                                        socials.map((sc, index) =>
                                            sc.value &&
                                            sc.type === "tiktok" ?
                                                <a key={index} href={sc.value} target='_blank'><svg width="15" height="28" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="white"/>
                                                </svg></a>
                                                :
                                                <a href={sc.value} target='_blank' key={index} className={
                                                                sc.type === "facebook" ? "fab fa-facebook-f" :
                                                                sc.type === "instagram" ? "fab fa-instagram" : 
                                                                sc.type === "dribbble" ? "fab fa-dribbble" : 
                                                                sc.type === "twitter" ? "fab fa-twitter" :  
                                                                sc.type === "telegram" ? "fab fa-telegram" :
                                                                sc.type === "vk" ? "fab fa-vk" :
                                                                sc.type === "whatsapp" ? "fab fa-whatsapp" :
                                                                "fab fa-facebook-f"
                                                            }></a>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </footer>}
    <ToTop widgets={widgets}/>
    <div className='footer-bottom'>{localizationsItems?.privacy}</div>
    {widgets && 
        <>
            {widgetsOpen ?
                <Widgets item={widgets} setWidgetsOpen={setWidgetsOpen} widgetsOpen={widgetsOpen}/>
            :
                <Widgets2 item={widgets}/> 
            }
        </>
    }
    </>
  )
}

export default Footer