import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useLangContext } from '../../i18n/ProvideLang';

const HeaderTop = ({item, socials}) => {
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const {lang, changeLanguage, localizationsItems, languages} = useLangContext();

    useEffect(() => {
        if(item){
            setEmail(item.find(it => it.type === "email")?.value);
            setAddress(item.find(it => it.type === "address")?.value);
            setPhone(item.find(it => it.type === "phone")?.value);
        }
    }, [item])
  return (
    <div className='header-top'>
        <div className='header-left'>
            {email &&
            <div className='header-flex'>
                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8142 3.97266C13.1853 4.46484 12.3923 5.06641 9.60327 7.08984C9.0564 7.5 8.04468 8.40234 7.08765 8.40234C6.10327 8.40234 5.1189 7.5 4.54468 7.08984C1.75562 5.06641 0.962646 4.46484 0.33374 3.97266C0.224365 3.89062 0.0876465 3.97266 0.0876465 4.10938V9.6875C0.0876465 10.4258 0.661865 11 1.40015 11H12.7751C13.4861 11 14.0876 10.4258 14.0876 9.6875V4.10938C14.0876 3.97266 13.9236 3.89062 13.8142 3.97266ZM7.08765 7.5C7.71655 7.52734 8.6189 6.70703 9.08374 6.37891C12.7205 3.75391 12.9939 3.50781 13.8142 2.85156C13.9783 2.74219 14.0876 2.55078 14.0876 2.33203V1.8125C14.0876 1.10156 13.4861 0.5 12.7751 0.5H1.40015C0.661865 0.5 0.0876465 1.10156 0.0876465 1.8125V2.33203C0.0876465 2.55078 0.169678 2.74219 0.33374 2.85156C1.15405 3.50781 1.42749 3.75391 5.06421 6.37891C5.52905 6.70703 6.4314 7.52734 7.08765 7.5Z" fill="#F4B22B"/>
                </svg>
                {email.map((em, index) =>
                    <span key={index}>{em}</span>
                )}
            </div>}
            {address &&
            <div className='header-flex'>
                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.15308 14.4766C5.39917 14.8594 5.97339 14.8594 6.21948 14.4766C10.2117 8.73438 10.95 8.13281 10.95 6C10.95 3.10156 8.59839 0.75 5.69995 0.75C2.77417 0.75 0.449951 3.10156 0.449951 6C0.449951 8.13281 1.16089 8.73438 5.15308 14.4766ZM5.69995 8.1875C4.46948 8.1875 3.51245 7.23047 3.51245 6C3.51245 4.79688 4.46948 3.8125 5.69995 3.8125C6.90308 3.8125 7.88745 4.79688 7.88745 6C7.88745 7.23047 6.90308 8.1875 5.69995 8.1875Z" fill="#F4B22B"/>
                </svg>
                {address.map((ad, index) =>
                    <span key={index}>{ad}</span>
                )}
            </div>}
            {phone &&
                phone.map((ph, index) =>
                    <div className='header-flex' key={index}>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.7908 11.3125L12.2908 9.8125C12.197 9.78125 12.1033 9.75 11.9783 9.75C11.7595 9.75 11.5408 9.875 11.4158 10.0312L9.85327 11.9375C7.41577 10.7812 5.47827 8.84375 4.32202 6.40625L6.22827 4.84375C6.38452 4.71875 6.50952 4.5 6.50952 4.25C6.50952 4.15625 6.47827 4.0625 6.44702 3.96875L4.94702 0.46875C4.82202 0.1875 4.54077 0 4.22827 0C4.19702 0 4.13452 0.03125 4.07202 0.03125L0.822021 0.78125C0.478271 0.875 0.259521 1.15625 0.259521 1.5C0.259521 9.53125 6.72827 16 14.7595 16C15.1033 16 15.3845 15.7812 15.4783 15.4375L16.2283 12.1875C16.2283 12.125 16.2283 12.0625 16.2283 12.0312C16.2283 11.7188 16.0408 11.4375 15.7908 11.3125Z" fill="#DEB636"/>
                        </svg>
                        <span className='nowrap'>{ph}</span>
                    </div>
                )
            }
        </div>
        <div className='header-right'>
            <>
            {socials?.find(el => el.value) &&
                socials.map((sc, index) =>
                    sc.value &&
                    sc.type === "tiktok" ?
                        <a key={index} href={sc.value} target='_blank'><svg width="15" height="28" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M34.1451 0H26.0556V32.6956C26.0556 36.5913 22.9444 39.7913 19.0725 39.7913C15.2007 39.7913 12.0894 36.5913 12.0894 32.6956C12.0894 28.8696 15.1315 25.7391 18.8651 25.6V17.3913C10.6374 17.5304 4 24.2783 4 32.6956C4 41.1827 10.7757 48 19.1417 48C27.5075 48 34.2833 41.1131 34.2833 32.6956V15.9304C37.3255 18.1565 41.059 19.4783 45 19.5479V11.3391C38.9157 11.1304 34.1451 6.12173 34.1451 0Z" fill="white"/>
                        </svg></a>
                        :
                        <a href={sc.value} target='_blank' key={index} className={
                                        sc.type === "facebook" ? "fab fa-facebook-f" :
                                        sc.type === "instagram" ? "fab fa-instagram" : 
                                        sc.type === "dribbble" ? "fab fa-dribbble" : 
                                        sc.type === "twitter" ? "fab fa-twitter" :  
                                        sc.type === "telegram" ? "fab fa-telegram" :
                                        sc.type === "vk" ? "fab fa-vk" :
                                        sc.type === "whatsapp" ? "fab fa-whatsapp" :
                                        "fab fa-facebook-f"
                                    }></a>
                )}
            </>
            {(languages?.length > 1) &&
            <Dropdown id="dropdown-basic" className='dropdown-lang'>
				<Dropdown.Toggle>
					<span>{lang}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu >
					{languages?.map((lang, index) =>
						<Dropdown.Item key={index} onClick={() => changeLanguage(lang.slug)}>{lang.title}</Dropdown.Item>
					)}
				</Dropdown.Menu>
			</Dropdown>}
        </div>
    </div>
  )
}

export default HeaderTop