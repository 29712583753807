import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ModalForm from './ModalForm'
import { useLangContext } from '../../i18n/ProvideLang';

const Opening2 = ({item}) => {
    const [show, setShow] = useState(false);
    const {localizationsItems} = useLangContext();
	const [image, setImage] = useState();

    const handleShow = () => {
		setShow(true);
	}

    useEffect(() => {
        if(item){
            setImage(item.elements.find(it => it.type === "banner-front")?.image)
        }
    }, [item])


  return (
    <div className='opening-2'>
        <div className="container-opening">
            <div className='position-relative opening-2-wrapper'>
                <div className='opening-2-width'>
                    <h1 className="banner-title t-u">{item.title}</h1>
                    <p className="lead lead-lg" dangerouslySetInnerHTML={{ __html: item.subtitle }}></p>
                    <div className='offer' dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    <div className="banner-btn-creative">
                        <div className="btn-creative btn-banner" onClick={handleShow}>{localizationsItems?.banner_button}</div>
                    </div>
                </div>
            </div>
            <div className="bg-image opening-2-img">
                <img src={image} alt="banner"/>
            </div>
        </div>
        {show && <ModalForm show={show} setShow={setShow}/>}
    </div>
  )
}

export default Opening2