import React from 'react'
import AboutItem from '../simple/AboutItem'

const About = ({item}) => {
  return (
	<div className="section section-x section-block tc-light has-bg-image">
		<div className="container">
			<div className="row justify-content-end">
				<div className="col-lg-12">
					<div className="text-block feature-area bg-darker">
						<div className="section-head">
							{item.subtitle && <h5 className="heading-xs dash">{item.subtitle}</h5>}
							<h2>{item.title}</h2>
						</div>
						<div className="row gutter-vr-40px">
							{item.elements?.map((card, index) =>
								<AboutItem card={card} key={index}/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="bg-image bg-fixed overlay-fall bg-image-loaded">
			<img src={item.steps_image} alt=""/>
		</div>
	</div>
  )
}

export default About