import React from 'react'
import ServiceCard from '../simple/ServiceCard'

const Services = ({item}) => {
  return (
	<div className="section section-x tc-grey back-color">
		<div className="container">
            <div className="row justify-content-center text-center">
				<div className="col-12">
					<div className="section-head section-md">
						{item.subtitle && <h5 className="heading-xs dash dash-both">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
					</div>
				</div>
			</div>
			<div className="row justify-content-center gutter-vr-30px text-center">
				{item.elements?.map((card, index) =>
				    <ServiceCard card={card} key={index}/>
				)}
			</div>
		</div>
	</div>
  )
}

export default Services