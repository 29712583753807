import React, { useEffect } from 'react'
import About from '../../components/complex/About'
import WhoWeAre from '../../components/complex/WhoWeAre'
import Portfolio from '../../components/complex/Portfolio'
import Services from '../../components/complex/Services'
import Faq from '../../components/complex/Faq'
import Partners from '../../components/complex/Partners'
import Form from '../../components/complex/Form'
import Steps from '../../components/complex/Steps'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from "../../services/general/appServices";
import MainBanner from '../../components/complex/MainBanner'
import Advantages from '../../components/complex/Advantages'
import { useLangContext } from '../../i18n/ProvideLang'
import { useNavigate, useParams } from 'react-router-dom'
import Reviews from '../../components/complex/Reviews'
import AdvertisingBanner from '../../components/complex/AdvertisingBanner'
import PricePackages from '../../components/complex/PricePackages'
import TextComponent from '../../components/complex/TextComponent'
import ServicePrice from '../../components/complex/ServicePrice'
import OpeningSlider from '../../components/complex/OpeningSlider'
import Opening2 from '../../components/complex/Opening2'
import Workers from '../../components/complex/Workers'
import ServicesComponent from '../../components/complex/ServicesComponent'

const SamplePage = () => {
    const {slug} = useParams();
    const{lang: lang2} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}/${slug}`);
            }
        }
    }, [lang])

  return (
    <>
        {loading ?
            <div className="preloader preloader-light preloader-alaska-light no-split">
                <span className="spinner spinner-alt spinner-light"></span>
            </div>
        
        :
        <div>
            {items &&
                items.elements?.map((elem, index) => 
                    elem.component_id ?
                        <div key={index} id={elem.component_id}>
                            {elem.type === "opening-5" ?
                                <MainBanner item={elem}/>
                            :
                            elem.type === "opening-slider" ?
                                <OpeningSlider item={elem}/>
                            :
                            elem.type === "opening-2" ?
                                <Opening2 item={elem}/>
                            :
                            elem.type === "advantages" ? 
                                <About item={elem}/>
                            :
                            elem.type === "steps" ?
                                elem.is_component ?
                                    <Services item={elem}/>
                                :
                                    <Steps item={elem}/>
                            :
                            elem.type === "steps2" ? 
                                <ServicesComponent item={elem}/>
                            :
                            elem.type === "gallery" ? 
                                <Portfolio item={elem}/>
                            :
                            elem.type === "partners-2" ? 
                                <Partners item={elem}/>
                            :
                            elem.type === "faq" ? 
                                <Faq item={elem}/>
                            :
                            elem.type === "about" ? 
                                <WhoWeAre item={elem}/>
                            :
                            elem.type === "about-2" ? 
                                <Steps item={elem} />
                            :
                            elem.type === "form" ?
                                <Form item={elem} dark={elem.is_dark}/>
                            :
                            elem.type === "advantages-2" ?
                                <Advantages item={elem} dark={elem.is_dark}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews item={elem}/>
                            :
                            elem.type === "opening-3" ?
                                <AdvertisingBanner item={elem}/>
                            :
                            elem.type === "packages" ?
                                <PricePackages item={elem}/>
                            :
                            elem.type === "service-price" ?
                                <ServicePrice item={elem}/>
                            :
                            elem.type === "text-component" ?
                                <TextComponent item={elem}/>
                            :
                            elem.type === "workers" ?
                                <Workers item={elem}/>
                            :
                                <></>
                            }
                        </div>
                        :
                        <div key={index}>
                            {elem.type === "opening-5" ?
                                <MainBanner item={elem}/>
                            :
                            elem.type === "opening-slider" ?
                                <OpeningSlider item={elem}/>
                            :
                            elem.type === "opening-2" ?
                                <Opening2 item={elem}/>
                            :
                            elem.type === "advantages" ? 
                                <About item={elem}/>
                            :
                            elem.type === "steps" ?
                                elem.is_component ?
                                    <Services item={elem}/>
                                :
                                    <Steps item={elem}/>
                            :
                            elem.type === "steps2" ? 
                                <ServicesComponent item={elem}/>
                            :
                            elem.type === "gallery" ? 
                                <Portfolio item={elem}/>
                            :
                            elem.type === "partners-2" ? 
                                <Partners item={elem}/>
                            :
                            elem.type === "faq" ? 
                                <Faq item={elem}/>
                            :
                            elem.type === "about" ? 
                                <WhoWeAre item={elem}/>
                            :
                            elem.type === "about-2" ? 
                                <Steps item={elem} />
                            :
                            elem.type === "form" ?
                                <Form item={elem} dark={elem.is_dark}/>
                            :
                            elem.type === "advantages-2" ?
                                <Advantages item={elem} dark={elem.is_dark}/>
                            :
                            elem.type === "reviews" ?
                                <Reviews item={elem}/>
                            :
                            elem.type === "opening-3" ?
                                <AdvertisingBanner item={elem}/>
                            :
                            elem.type === "packages" ?
                                <PricePackages item={elem}/>
                            :
                            elem.type === "service-price" ?
                                <ServicePrice item={elem}/>
                            :
                            elem.type === "text-component" ?
                                <TextComponent item={elem}/>
                            :
                            elem.type === "workers" ?
                                <Workers item={elem}/>
                            :
                                <></>
                            }
                        </div>
                    
                )
            }
        </div>
        }
    </>
  )
}

export default SamplePage