import React from 'react'
import CollapseItem from '../simple/CollapseItem'

const Faq = ({item}) => {
  return (
	<div className="section section-x bg-secondary tc-grey">
		<div className="container">
			<div className="row justify-content-center text-center">
				<div className="col-12">
					<div className="section-head section-md">
						{item.subtitle && <h5 className="heading-xs dash dash-both">{item.subtitle}</h5>}
						<h2>{item.title}</h2>
					</div>
				</div>
			</div>
			<div className="row gutter-vr-30px">
				{item.elements?.map((card, index) => 
					<div className="col-12" key={index}>
                        <CollapseItem card={card}/>
					</div>
				)}
                						
			</div>
		</div>
        {item.image &&
        <div className="bg-image bg-fixed">
			<img src={item.image} alt=""/>
		</div>}
	</div>
  )
}

export default Faq