import React, { useEffect, useState } from 'react'
import Step from '../simple/Step'

const Steps = ({item}) => {
	const [image, setImage] = useState();
	const [text, setText] = useState();

	useEffect(() => {
		if(item){
			setImage(item.elements?.find(it => it.type === "images")?.elements[0])
			setText(item.elements?.find(it => it.type === "text")?.elements[0])
		}
	}, [item])

  return (
	<div className="section section-x section-counter tc-light">
		<div className="container">
			<div className="row justify-content-between align-items-center gutter-vr-30px">
				<div className="col-lg-5 col-md-12">
					<div className="text-box mrm-20">
						<h2>{item.title}</h2>
						<p className='description' dangerouslySetInnerHTML={{ __html: item.description }}></p>
					</div>
				</div>
				<div className="col-lg-6 col-md-12">
					{text ?
						<div className="col-12">
							<div className="counter counter-s2 counter-bdr">
								<div className='description' dangerouslySetInnerHTML={{ __html: text.description }}></div>
							</div>
						</div>
						:
						<div className="row no-gutters">
							{item.elements?.map((card, index) =>
								<Step card={card} key={index} index={index+1}/>
							)}
						</div>	
					}
				</div>
			</div>
		</div>
		<div className="bg-image bg-fixed">
			{image ?
				<img src={image} alt=""/>
				:
				<img src={item.steps_image} alt=""/>
			}
		</div>
	</div>
  )
}

export default Steps