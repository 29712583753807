import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const MenuItem = ({it, setIsMob}) => {
    const [open, setOpen] = useState(true);
;
  return (
    <li className="menu-item  has-sub">
        <NavLink className="menu-link nav-link menu-toggle" target={it.type} to={it.value} onClick={() => setOpen(!open)}>{it.title}</NavLink>
        <ul className={open ? "menu-sub menu-drop" : "menu-sub menu-drop menu-sub-none"}>
            {it.items?.map((el, index) =>
                <li className="menu-item" key={index}>
                    {el.value?.startsWith('#') ?
                        <a onClick={() => setIsMob(false)} href={el.value}>{el.title}</a>
                            :
                        <NavLink onClick={() => setIsMob(false)} className="menu-link nav-link" target={el.target} to={el.value}>{el.title}</NavLink>
                    }
                </li>
            )}
        </ul>
    </li>
  )
}

export default MenuItem