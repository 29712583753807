import React, { useState } from 'react'
import WorkerModal from './WorkerModal'

const Workers = ({item}) => {
    const [show, setShow] = useState();
    const [card, setCard] = useState();

    const handleShow = (item) => {
        setCard(item);
        setShow(true);
    }

  return (
	<div className="section section-x section-project pb-0 tc-grey-alt" id="workers">
        <div className='text-center'>
            <div className="container">
                    <div className="portfolio-wrapper">
                        <div className="section-head">
                            <h2>{item.title}</h2>
                            {item.subtitle && <h5 className="heading-xs">{item.subtitle}</h5>}
                        </div>
                    </div>
            </div>
        </div>
        <div className="container">
            <div className="row project project-v5 no-gutters">
                {item.elements?.map((card, index) =>
                    <div className='col-sm-6 col-md-4 col-xl-3 filtr-item' onClick={() => handleShow(card)} key={index}>
                        <div className="project-item" key={index}>
                            <div className="worker-image">
                                <img src={card.image} alt="" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        {show && <WorkerModal show={show} setShow={setShow} card={card} image={item.steps_image}/>}
	</div>
  )
}

export default Workers