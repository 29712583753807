import React from 'react'

const Step = ({card, index}) => {
  return (
    <div className="col-6">
        <div className="counter counter-s2 counter-bdr">
            <div className="counter-icon">
                {index}
            </div>
            <div className="counter-content counter-content-s2">
                <h2 className="mrm-4 count" data-count="375">{card.title}</h2>
                <p>{card.subtitle}</p>
            </div>
        </div>
    </div>
  )
}

export default Step